import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: "/",
    name: "sign-in2",
    component: () => import("../views/LoginView.vue"),
  },

  {
    path: "/sign-in",
    name: "sign-in",
    component: () => import("../views/LoginView.vue"),
  },

  {
    path: "/orders",
    name: "orders",
    component: () => import("../views/OrdersView.vue"),
  },

  {
    path: "/my-jobs",
    name: "my-jobs",
    component: () => import("../views/WorksView.vue"),
  },

  {
    path: "/earnings",
    name: "earnings",
    component: () => import("../views/EarningsView.vue"),
  },
  {
    path: "/explore",
    name: "explore",
    component: () => import("../views/ExploreView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
